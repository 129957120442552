export function prefix(location, ...prefixes) {
  return prefixes.some(
    (prefix) => location.href.indexOf(`${location.origin}/${prefix}`) !== -1,
  )
}

export function authorization(location) {
  return prefix(location, 'auth')
}

export function navbar() {
  return true
}

export function sidebar() {
  return true
}

export function diy(location) {
  return prefix(location, 'do-it-yourself')
}

export function ht(location) {
  return prefix(location, 'how-to')
}

// export function gh(location) {
//   return prefix(location, 'get-help')
// }

export function ticket(location) {
  return prefix(location, 'ticket-status')
}

export function backOffice(location) {
  return prefix(location, 'back-office')
}

export function brief(location) {
  return prefix(location, 'brief-consulting')
}

export function companyBrief(location) {
  return prefix(location, 'company-brief')
}