import { registerApplication, start } from "single-spa";
import { navbar, sidebar, diy, ht, authorization, ticket, backOffice, brief, companyBrief } from "./activity-functions";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

registerApplication("@isc/navbar", () => System.import("@isc/navbar"), navbar, {
	domElement: document.getElementById("navbar"),
});

registerApplication("@advise/sidebar", () => System.import("@advise/sidebar"), sidebar, {
	domElement: document.getElementById("sidebar"),
});

registerApplication("@advise/diy", () => System.import("@advise/diy"), diy, {
	domElement: document.getElementById("diy"),
});

registerApplication("@advise/ht", () => System.import("@advise/ht"), ht, { domElement: document.getElementById("ht") });

// registerApplication("@advise/gh", () => System.import("@advise/gh"), gh, { domElement: document.getElementById("gh") });

registerApplication("@advise/authorization", () => System.import("@advise/authorization"), authorization, {
	domElement: document.getElementById("authorization"),
});

registerApplication("@advise/ticket-status", () => System.import("@advise/ticket-status"), ticket, {
	domElement: document.getElementById("ticket-status"),
});

registerApplication("@advise/back-office", () => System.import("@advise/back-office"), backOffice, {
	domElement: document.getElementById("back-office"),
});
registerApplication("@advise/brief-consulting", () => System.import("@advise/brief-consulting"), brief, {
	domElement: document.getElementById("brief-consulting"),
});
registerApplication("@advise/company-brief", () => System.import("@advise/company-brief"), companyBrief, {
	domElement: document.getElementById("company-brief"),
});

start();
